<template>
  <div class="main-box" v-if="shopDetail" @click.stop="closemodel">
    <a-breadcrumb>
      <a-breadcrumb-item
        ><router-link to="/knowledgeMall/shopHome"
          >书课专区</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item v-if="$route.query.name"
        ><a @click="$router.go(-1)">{{
          $route.query.name
        }}</a></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">{{
        shopDetail.courseName
      }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="content">
      <!-- 左侧商品图 -->
      <div class="shopimg">
        <img class="img" :src="productObj ? productObj.photo : imgSrc" alt="" />
        <div class="equity" v-if="shopDetail.kfxhMumber">
          <img src="@/assets/image/knowledgeMall/2024090901.png" alt="">
          会员权益：购买可成为康复协会会员，查看<a @click="isEquityShow=true">《会员权益》</a>
        </div>
      </div>
      <!-- 右侧商品详情 -->
      <div class="shopDetail">
        <!-- 名称 -->
        <div class="name-inner flex-between">
          <p class="name" v-if="$route.query.productId">{{ productObj ? (productObj.title?productObj.title:productObj.relatedProductName) : shopDetail.courseName }}</p>
          <span class="tags">{{formatNumber(shopDetail.productClicksNumber)}}人想学</span>
        </div>
        <!-- 价格、标签 -->
        <div class="price-inner flex-between">
          <div class="price">
            <p
              class="new"
              v-if="$route.query.productId && !!shopDetail.couponPrice"
            >
              ￥<span v-if="shopDetail.couponPrice"
                >{{ managePrice( (productObj ? productObj.price : shopDetail.couponPrice) * 1)[0]
                }}<span class="new_point"
                  >.{{ managePrice( (productObj ? productObj.price : shopDetail.couponPrice) * 1)[1] }}</span
                ></span
              >
              <span v-else>免费</span>
            </p>
            <p
              class="new"
              v-if="$route.query.productId && !shopDetail.couponPrice"
            >
              免费
            </p>
            <p class="old" v-if="$route.query.productId && shopDetail.original && shopDetail.couponPrice && !specsList.length">
              {{
                shopDetail.originalPrice
                  ? "￥" + (shopDetail.originalPrice * 1).toFixed(2)
                  : "免费"
              }}
            </p>
            <!-- 领券入口 -->
            <div class="coupon-btn" v-if="couponList && couponList.length && shopDetail.couponPrice">
              <img @click.stop="couponFlag = !couponFlag" src="@/assets/image/knowledgeMall/2024090601.png" alt="">
              <div class="couponList" v-show="couponFlag">
                <div class="coupon" :class="{geted:ite.drawId}" v-for="(ite, ind) in couponList" :key="ind">
                  <div class="couponLeft">
                    <!-- 满减券 -->
                    <p class="price" v-if="ite.type == 1">￥
                      <span 
                        :style="[
                          {
                            fontSize:(ite.couponPrice+'').length < 4?'30px':(ite.couponPrice+'').length == 4?'24px':'18px'
                          },
                          {
                            lineHeight:(ite.couponPrice+'').length <= 4?'45px':'37px'
                          }
                        ]
                        ">{{ite.couponPrice}}</span></p>
                    <!-- 折扣券 -->
                    <p class="price" v-else><span>{{ite.discount}}</span>折</p>
                    <p class="condition">{{ite.type == 1?('满'+ite.fullPrice):ite.type == 3?('满'+ite.discountPrice+(ite.discountType==1?'件':'元')):''}}可用</p>
                  </div>
                  <div class="couponRight">
                    <p class="couponName mallHidden">{{ite.name}}</p>
                    <p class="useTime">{{useTime(ite)}}</p>
                    <p class="scope mallHidden">【{{ite.receiveDesc}}】</p>
                  </div>
                  <div v-if="!ite.drawId" @click.stop="receiveCoupon(ite)" class="couponBtn">领取</div>
                  <div v-else class="couponStyle">已领取</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 课程标签 -->
          <div class="label-list" v-if="shopDetail.productTagsList.length">
            <span class="label-item" v-for="item in shopDetail.productTagsList" :key="item.id" @click="goTagsList(item)">{{item.tagName}}</span>
          </div>
          <!-- 课程视频数量 -->
          <div class="describe" v-else>
            {{ shopDetail.categoryName }} | 视频数量：{{ shopDetail.classHour }}个
          </div>
        </div>
        <!-- 证书和赠品 -->
        <div class="gift-inner" v-if="(seriesproductList.length && shopDetail.couponPrice) || (shopDetail.relevantCertificateList && shopDetail.relevantCertificateList.length)">
          <!-- 证书 -->
          <div class="side-item" v-if="shopDetail.relevantCertificateList && shopDetail.relevantCertificateList.length">
            <span class="title">
              <img src="@/assets/image/knowledgeMall/2024090602.png" alt="">
              证书：
            </span>
            <div class="side-r">
              <p class="name" @click="OpenCertificateModal(item)" v-for="(item,index) in shopDetail.relevantCertificateList" :key="index">
                {{item.name}}
                <img class="see-btn" src="@/assets/image/knowledgeMall/20241021-185813.png" alt="">
              </p>
            </div>
          </div>
          <!-- 赠品 -->
          <div class="side-item" v-if="seriesproductList.length && shopDetail.couponPrice">
            <span class="title">
              <img src="@/assets/image/knowledgeMall/2024090603.png" alt="">
              赠品：
            </span>
            <div class="side-r">
              <div class="flex" v-for="item in seriesproductList" :key="'#a' + item.relatedProductId" @click="goShopDetail(item)">
                <img class="photo" :src="item.photo" alt="">
                <div>
                  <p class="name">{{item.title? item.title : item.relatedProductName}}</p>
                  <span class="name">x1</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 规格 -->
        <div class="schedule-inner" v-if="specsList.length && !shopDetail.have">
          <span class="title">选择规格：</span>
          <div class="list">
            <span class="item" :class="{'item-hover' : item.relatedProductId == specsId}" @click="onSpecsSelected(item)" v-for="item in specsList" :key="'#b' + item.relatedProductId">{{item.relatedProductName}}</span>
          </div>
        </div>
        <!-- 班期 -->
        <div class="schedule-inner" v-if="($route.query.productId && classList.length && !shopDetail.have) || shopDetail.buyLimitNum == 1">
          <span class="title">选择班期：</span>
          <div>
            <div class="list">
              <div class="item class" @click="selectOpenTimeId(ite)" :class="{'item-hover' : openTimeId == ite.openTimeId}" v-for="(ite, ind) in classList" :key="ind">
                <div class="flex-between">
                  <p class="name">
                    <img :src="openTimeId == ite.openTimeId ? classIconActive : classIcon" alt="">
                    {{ ite.className }}
                  </p>
                  <span class="num">剩余：<i>{{ite.currentNumber > 0 ? ite.currentNumber : 0 }}</i></span>
                </div>
                <p class="time">{{ ite.startTime }} ~ {{ ite.endTime }}</p>
              </div>
            </div>
            <div class="address" v-if="classItem && classItem.provinceTxt">
              <img src="@/assets/image/knowledgeMall/2024090606.png" alt="">上课地点：{{classItem.provinceTxt}}{{classItem.cityTxt}}{{classItem.districtTxt}}{{classItem.address}}
            </div>
          </div>
        </div>
        <!-- 配套商品 -->
        <div class="schedule-inner" style="align-items: flex-start;" v-if="productList.length">
          <span class="title">配套商品：</span>
          <div class="list">
            <div class="item together" :class="{'item-hover' : item.count == 1}" @click="toggleSelection(index)" v-for="(item, index) in productList" :key="'#c' + item.relatedProductId">
              <img @click.stop="goShopDetail(item)" :src="item.photo" alt="">
              <div class="side-r">
                <p class="name">{{item.title? item.title : item.relatedProductName}}</p>
                <span class="price">{{item.price ? ('￥' + item.price.toFixed(2)) : ''}}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="step-num"
          v-if="shopDetail.buyLimitNum == 1"
        >
          <span>选择数量：</span>
          <div class="count">
            <a-icon class="icon" type="minus" @click="changeCourseNum(-1)" />
            <a-input-number
              id="inputNumber"
              v-model="courseNum"
              :min="1"
            />
            <a-icon class="icon" type="plus" @click="changeCourseNum(1)" />
          </div>
        </div>

        <!-- 购买须知 -->
        <a-radio class="bayNote" :checked="prebuyNoticeFlag" @change="prebuyNoticeFlag = true" v-if="(!shopDetail.have || shopDetail.buyLimitNum) && shopDetail.prebuyNotice">我已仔细阅读并了解该 <span @click.prevent="prebuyNoticeVisible = true" class="funcBtn">《购买须知》</span></a-radio>

        <!-- 线下课 或 无视频 -->
        <div class="buyBtns" v-if="shopDetail.have && shopDetail.courseClass == 2 && !shopDetail.hasVideoFlag" >
          <p class="btn buy-button" @click="$message.warning('您已报名该培训，请根据培训时间参加培训。')">已报名</p> 
        </div>
        <div class="buyBtns" v-else>
          <!-- 试看按钮 -->
          <div
            v-if="!shopDetail.have && freeSelectionObj.fileId"
            class="btn try-button"
            @click="onPlayFreeVideo"
          >
            <a-icon type="play-square" />
            <p>立即试看</p>
          </div>
          <div
            v-if="(!!shopDetail.couponPrice && !shopDetail.have) || (shopDetail.buyLimitNum == 1)"
            class="btn car-button"
            @click="joinShopCar"
          >
            <p>加入购物车</p>
          </div>
          <div
            v-if="!!shopDetail.couponPrice"
            class="btn buy-button"
            @click="buyNow"
          >
            <a-icon v-if="!shopDetail.have" type="shopping" />
            <p v-if="shopDetail.have">
              {{ shopDetail.buyLimitNum?'再次购买':shopDetail.isOpenClass == 1 ? "待开课" : "去学习" }}
            </p>
            <p v-else>立即购买</p>
          </div>
          <div
            v-else
            class="btn buy-button"
            @click="joinStudy"
          >
            <a-icon v-if="!shopDetail.have" type="shopping" />
            <p v-if="shopDetail.have">
              {{ shopDetail.isOpenClass == 1 ? "待开课" : "去学习" }}
            </p>
            <p v-else>{{shopDetail.have && false?'已':''}}加入学习</p>
          </div>
          
        </div>
      </div>
    </div>
    <!-- tabs -->
    <div class="tabs-box">
      <!-- 切换 -->
      <div class="tabs">
        <a
          class="tabsdiv"
          v-if="$route.query.productId"
          @click="tabIndex = 0"
          :class="{'tabs-hover' : tabIndex == 0}"
        >
          <span>课程介绍</span>
        </a>
        <a
          class="tabsdiv"
          v-if="$route.query.productId && tree.length"
          @click="tabIndex = 1"
          :class="{'tabs-hover' : tabIndex == 1}"
        >
          <span>目录</span>
        </a>
        <a
          v-if="materialList.length"
          class="tabsdiv"
          @click="tabIndex = 2"
          :class="{'tabs-hover' : tabIndex == 2}"
        >
          <span>学习资料</span>
        </a>
        <a
          v-if="collectionList.length"
          class="tabsdiv"
          @click="tabIndex = 3"
          :class="{'tabs-hover' : tabIndex == 3}"
        >
          <span>课程系列</span>
        </a>
      </div>
      <!-- 视图 -->
      <div class="contenttab">
        <!-- 课程介绍 -->
        <div v-if="tabIndex == 0">
          <div class="barTabNav" ref="barTabNav">
            <span class="tab-item" :class="{'tab-hover' : index == pointNum}" @click="navClick(item,index)" v-for="(item,index) in detailTabs" :key="item.navTarget">{{item.text}}</span>
          </div>
          <div  class="barTabNav barTabNav-default" :class="{'barTabNav-Top' : barTabNavTop < 0}">
            <span class="tab-item" :class="{'tab-hover' : index == pointNum}" @click="navClick(item,index)" v-for="(item,index) in detailTabs" :key="item.navTarget">{{item.text}}</span>
          </div>
          <!-- 详情 -->
          <div v-if="shopDetail.detail" id="item1">
            <!-- <div class="h4" style="margin-top:0">
              <span class="title">课程介绍</span>
            </div> -->
            <div v-html="shopDetail.detail"></div>
          </div>

          <!-- 学习指导 -->
          <div v-if="shopDetail.studentInstructions" id="item2">
            <div class="h4">
              <span class="title">学习指导</span>
            </div>
            <div v-html="shopDetail.studentInstructions"></div>
          </div>

          <!-- 相关证书 -->
          <div v-if="shopDetail.relevantCertificateList && shopDetail.relevantCertificateList.length" id="item3">
            <div class="h4">
              <span class="title">相关证书</span>
            </div>
            <div
              class="relevantCertificateList"
              v-for="(item, index) in shopDetail.relevantCertificateList"
              :key="'#a' + index"
            >
              <img :src="item.picture" alt="" />
              <p>{{ item.name }}</p>
            </div>
          </div>

          <!-- 授课老师 -->
          <div v-if="shopDetail.teacherList && shopDetail.teacherList.length" id="item4">
            <div class="h4">
              <span class="title">授课老师</span>
            </div>
            <div
              class="teacherItem"
              v-for="(item, index) in shopDetail.teacherList"
              :key="'#b' + index"
            >
              <div class="flexBox">
                <div class="imgTitle">
                  <div
                    class="pictureName"
                    style="background: #E5E5E5"
                  >
                    <img :src="item.squarePicture" alt="" />
                  </div>
                  <p class="name" style="background: #E5E5E5">
                    {{ item.name }}
                  </p>
                  <div
                    class="border"
                    style="border-color:#E5E5E5"
                  ></div>
                  <div
                    class="triangle"
                    style="border-left-color:#E5E5E5 "
                  ></div>
                </div>
                <swiper
                  ref="mySwiper"
                  class="titleList"
                  :options="teacherSwiperOptions"
                >
                  <swiper-slide
                    v-for="(itemI, indexI) in item.title"
                    :key="indexI"
                  >
                    <div
                      class="titleItem"
                      v-for="(itemII, indexII) in itemI"
                      :key="indexII"
                    >
                      <span style="background: #E5E5E5"></span
                      >{{ itemII }}
                    </div>
                  </swiper-slide>
                  <div
                    v-show="item.title.length > 1"
                    v-for="(itemI, indexI) in item.title"
                    :key="indexI + 1"
                    class="swiper-pagination"
                    slot="pagination"
                  ></div>
                </swiper>
              </div>
              <div class="detail">
                {{regtxt(item.introduction)}}
              </div>
              <img
                src="@/assets/image/knowledgeMall/tag.png"
                alt=""
                class="fly"
              />
            </div>
          </div>
        </div>
        <!-- 目录 -->
        <template v-else-if="tabIndex == 1">
          <forTree :tree="tree" @nodeClick="treeClick" :isOrder="true" :courseId="courseId" :courseName="shopDetail.courseName" :isHave="shopDetail.have" />
        </template>
        <!-- 学习资料 -->
        <div v-else-if="tabIndex == 2">
          <div class="material" @click="lookmaterial(item)" v-for="(item,index) in materialList" :key="index">
            <div class="mess">
              <img class="materialIcon" src="@/assets/image/knowledgeMall/materialIcon.png" alt="">
              <p class="courseName mallHidden">{{item.name}}</p>
              <img class="courseIcon" v-if="item.materialType == 1" src="@/assets/image/knowledgeMall/courseIcon.png" alt="">
              <p class="sectionName" v-else>/ {{item.sectionName}}</p>
            </div>
            <div class="lookBtn"><p>查看</p><a-icon class="icon" type="right" /></div>
          </div>
        </div>
        <!-- 课程系列 -->
        <div v-else-if="tabIndex == 3">
          <div class="collection-list">
            <div class="collection-item" v-for="item in collectionList" :key="item.relationId" @click="goShopDetail(item)">
              <img class="cover" :src="item.photo" alt="">
              <div class="info">
                <p class="name">{{item.title? item.title : item.relatedProductName}}</p>
                <p class="price"><i v-show="item.price">¥</i>{{item.price?item.price.toFixed(2):'免费'}}</p>
                <span class="go">
                  查看详情
                  <img src="@/assets/image/knowledgeMall/circularright2.png" alt="">  
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Shopwarning :visible="visible" :fontText="fontText" />

    <!-- 请先完成身份认证弹窗 -->
    <TipsModal 
      ConfirmBtnText="去认证"
      :isPublicTipsShow="confirmPopup" 
      tipsText="购买该课程需要身份认证"
      @setEvent="goAuthentication" />
    <!-- 请先选择班期 -->
    <TipsModal 
      :isCancelBtnShow="false"
      ConfirmBtnText="我知道了"
      :isPublicTipsShow="isPublicTipsShow" 
      :tipsText="tipsText"
      @setEvent="isPublicTipsShow = false" />
    <!-- 提交资质弹框 -->
    <SubmitQualification
      :courseId="courseId"
      :tipsText="tipsText"
      :qualificationvisible="qualificationvisible"
      @onCancel="cancelQuali"
      @onConfirm="confirmQuali"/>
    <Sidebar ref="sidebar"/>
    <EquityModal :isEquityShow = "isEquityShow" @closeEvent="isEquityShow = false" />

    <!-- 证书查看详情 -->
    <a-modal
      :title="certificateForm.name"
      align='center'
      :visible="certificateShow"
      @cancel="certificateShow = false"
      :width=700
    >
      <div>
        <img style="width:90%" :src="certificateForm.picture" alt="">
      </div>
      <template #footer>
        <a-button @click="certificateShow = false">关闭</a-button>
      </template>
    </a-modal>

    <!-- 购买须知弹框 -->
    <a-modal
      v-if="shopDetail.prebuyNotice && !shopDetail.have"
      title="《购买须知》"
      :visible="prebuyNoticeVisible"
      @cancel="prebuyNoticeVisible = false"
    >
      <template #footer>
        <a-button class="enterNote" @click="prebuyNoticeVisible = false,prebuyNoticeFlag = true">我已了解该须知</a-button>
      </template>
      <div v-richText="{htmlText: shopDetail.prebuyNotice, styleText: 'p {margin: 0;}'}"></div>
    </a-modal>

    <!-- 未购课提示窗 -->
    <a-modal
      width="480px"
      :visible="isCourseTipsShow"
      :footer="false"
      @cancel="isCourseTipsShow = false"
    >
      <div class="modal-content">
        <p class="tips">
          <img
            src="@/assets/image/personalCenter/2022050913.png"
            alt=""
          />提示信息
        </p>
        <div class="content">
          购买该课程需要先购买<a  v-for="(item,index) in buyCourseList" @click="goBuyProduct(item.id)" :key="item.id">{{item.name}}<template v-if="(index+1) != buyCourseList.length">、</template></a>
        </div>
        <div class="btn-foot">
          <p class="all-btn-small" @click="isCourseTipsShow = false">我知道了</p>
        </div>
      </div>
      <template #footer></template>
    </a-modal>
  </div>
</template>

<script>
import {userTrack} from '@/unit/userTrack.js';
import forTree from "@/components/forTree/index.vue";
import SubmitQualification from '@/components/model/submitQualification.vue' // 提交资质
import Sidebar from "@/components/sidebar.vue";
import EquityModal from "./EquityModal.vue";
export default {
  // 可用组件的哈希表
  components: { 
    forTree,
    SubmitQualification, // 提交资质
    Sidebar,
    EquityModal
  },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      shopDetail: null,  // 课程详情信息
      productCode:0,
      tipsText: "", //
      isEquityShow:false,
      couponFlag: false, // 优惠券弹框状态
      qualificationvisible: false, // 提交资质弹框
      isPublicTipsShow: false,
      isCourseTipsShow: false,
      buyCourseList:[],
      confirmPopup: false, // 身份认证弹窗标识
      clientWidth: 0, // 视口宽度
      imgSrc1: require("@/assets/image/temporary/Group4627.png"),
      specsId: "", // 规格id
      productObj: null, 
      freeSelectionObj: {},//免费试看章节
      openTimeId: "", // 所选班期id
      classItem: null, //所选班期对象
      courseId: "",
      classList: [], // 商品-班期list
      visible: false,
      fontText: "", // 文本
      tabIndex: 0, // 课程详情底部tab索引
      formData: {},
      tree: [],
      mallClass: false, // 更多期数状态
      imgSrc: require("@/assets/image/temporary/pic_nav.jpg"),
      tabMenu: ["全部", "213"],
      courseNum:1,
      couponList: null, // 优惠券列表
      teacherSwiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      // 相关配套商品
      productList: [],
       // 附赠商品
      seriesproductList: [],
      // 规格商品
      specsList:[],
      // 系列商品
      collectionList: [],
      colorList: [],
      detailTabs: [],

      certificateForm:{}, // 证书内容
      certificateShow:false, 

      barTabNavTop:100, // 距顶距离
      pointNum:0,
 
      swiperOptions: {
        slidesPerView: "auto",
        centeredSlides: false,
        // spaceBetween: 30,
        centeredSlidesBounds: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "my-button-disabled",
        },
        // Some Swiper option/callback...
      },
      materialList: [], // 学习资料列表

      prebuyNoticeFlag: false, // 购买须知是否同意
      prebuyNoticeVisible: false, // 购买须知弹框控制器

      classIcon: require('@/assets/image/knowledgeMall/2024090605.png'),  //班期默认icon
      classIconActive: require('@/assets/image/knowledgeMall/2024090902.png'),  // 已选班期icon
    };
  },

  directives: {
    // shadow DOM 处理富文本样式避免被外界样式污染
    richText: {
      inserted: function(el, binding) {
        const shadowRoot = el.attachShadow({ mode: 'closed' })
        let htmlText, styleText
        if (typeof binding.value === 'object') {
          htmlText = binding.value.htmlText
          styleText = binding.value.styleText
        } else {
          htmlText = binding.value
        }
        shadowRoot.innerHTML = htmlText
        if (styleText) {
          // 给富文本添加样式
          const style = document.createElement('style')
          style.textContent = styleText
          shadowRoot.appendChild(style)
        }
      }
    }
  },
  // 事件处理器
  methods: {
    closemodel(){
      if(this.couponFlag) {
        this.couponFlag = false;
      }
    },
    useTime(ite) {
      if(ite.times == 2) {
        if(ite.startTime && ite.lapseTime) {
          return ite.startTime.split(' ')[0] + '-' + ite.lapseTime.split(' ')[0]
        }
      }else if(ite.times == 3) {
        if(ite.lapseTime) {
          return '至'+ ite.lapseTime.split(' ')[0]
        }
        if(ite.days) {
          return '领取后'+ite.days+'天内可用'
        }
      }else if(ite.times == 1) {
        return '不限'
      }
    },
    formatNumber(num) {
      if (num < 1000) {
        return num.toString();
      } else if (num < 10000) {
        return (num / 1000).toFixed(1) + 'k';
      } else { // 处理到千万级别的数字
        if (num < 1000000) {
          return (num / 10000).toFixed(1) + 'w';
        } else {
          return (num / 10000).toFixed(1) + 'w'; // 这里也可以使用更高级的单位如M（百万），具体取决于需求
        }
      }
    },
    // 去学习
    goStudy(e) {
      if (e.validStatus == 2) {
        this.$message.warning("请前往学习中心-在学课程中申请延期");
        return;
      }
      if (!e.hasVideoFlag && !e.hasSectionFlag) {// 没有章节、没有视频，提示文字
        return this.$message.warning("课程暂未更新，请耐心等待开课")
      }
      if (e.isOpenClass == 1) {
        let arr = e.startTime.split("-");
        this.$message.warning(
          "课程学习尚未开始，开始时间为" +
            arr[0] +
            "年" +
            arr[1] +
            "月" +
            arr[2] +
            "日"
        );
      } else {
        // 课程是否顺序播放  watchByCatalogueOrder 1.按顺序播放  0.不按顺序播放
        if(this.shopDetail.watchByCatalogueOrder == 1){
          this.$router.push(
            "/curriculum/course?courseId=" +
            this.$AES.encode_data(String(e.goStudyCourseId)) +
            "&isBuy=" +
            "&isHave=1"
          );
        } else {// 跳转不需要按顺序播放的课程详情页
          sessionStorage.removeItem("NextVod");

          this.$router.push(
            "/curriculum/courseRandom?courseId=" +
            this.$AES.encode_data(String(e.goStudyCourseId)) +
            "&isHave=1"
          );
        }
      }
    },
    // 规格选择
    onSpecsSelected(e) {
      if(this.specsId == e.relatedProductId) return
      this.productObj = e;
      this.openTimeId = '';

      this.getshopCourseDateList(e.courseId) // 查询班期
      this.getTree() // 更新目录
    
      this.specsId = e.relatedProductId;
    },
    // 修改相关商品选择状态
    toggleSelection(index){
      var arry = JSON.parse(JSON.stringify(this.productList))
      const newValue = arry[index].count === 1 ? 0 : 1;
      arry[index].count = newValue;
      this.productList = arry
    },
    goTagsList(e){
      this.$router.push(
        "/knowledgeMall/courseTagsList?tagId=" +
          this.$AES.encode_data(e.tagId + "") + 
          "&name=" + e.tagName + "&type=课程"
      );
    },

    goBuyProduct(id){
      this.isCourseTipsShow = false
      let item = {
        productType:1, 
        relatedProductId: id
      }
      this.goShopDetail(item)
    },

    // 跳转商品详情
    goShopDetail(item){
      if (item.productType == 1) {
        this.$router.push(
          "/knowledgeMall/courseDetail?productId=" +
            this.$AES.encode_data(item.relatedProductId + "") +
            "&code=" +
            item.code
        );
        this.$router.go(0) // 跳当前页刷新页面
      } else if (
        item.productType == 2 ||
        item.productType == 3
      ) {
        this.$router.push(
          "/knowledgeMall/curriculumDetail?productId=" +
            this.$AES.encode_data(item.relatedProductId + "")
        );
      } else if (item.productType == 4) {
        this.$router.push(
          "/knowledgeMall/examDetail?productId=" +
            this.$AES.encode_data(item.relatedProductId + "")
        );
      }
    },

    // 判断课程是否可购买
    async checkCanBuy(){
      let res = await this.$ajax({
				url:'/hxclass-pc/course/check-can-buy',
				method:'get',
				params:{
					courseId:this.courseId
				}
			})
      if(res.code == 1000){ // 需购买课程
        // this.tipsText = '购买该课程需要先购买<span style="color:#15B7DD;white-space:normal;word-break:break-all;word-wrap:break-word;">'+res.data.courseNameList.join('、')+'</span>。';
        // this.isPublicTipsShow = true
        
        this.buyCourseList = []
        res.data.courseNameList.forEach((element, index) => {
          this.buyCourseList.push({
            id: res.data.productIdList[index],
            name: element,
          })
        });
        this.isCourseTipsShow = true;
      }else if(res.code == 1001){ // 需提交资质
        this.tipsText = '';
        this.qualificationvisible = true;
      }else if(res.code == 1002){ // 已提交资质待审核
        this.tipsText = '您已成功提交申请，1-3个工作日内将完成审核，审核结果将以短信的方式通知，请留意。';
        this.isPublicTipsShow = true;
      }else if(res.code == 1003){ // 审核被驳回
        this.tipsText = '';
        this.qualificationvisible = true;
      }else if(res.code == 1004){ // 需购买课程且提交资质
        this.tipsText = '购买该课程需要先购买<span style="color:#15B7DD;white-space:normal;word-break:break-all;word-wrap:break-word;">'+res.data.courseNameList.join('、')+'</span>，如您已获得上述资质，请提交您的资质信息，后台审核通过后方可报名。';
        this.qualificationvisible = true;
      }else if(res.code == 1005){ // 课程无可用班期
        this.$message.error(res.message);
      }else if (res.code == 1006) { // 该课程已购买
        this.$message.error(res.message);
      }
      return res;
    },
    async joinShopCar(){
      // 加入购物车校验
      if(this.beforeBuyCheck(false)){return}

      let res = await this.checkCanBuy()
      if(res.code == 200 && res.success){
        this.$ajax({
          url:'/hxclass-pc/pc-mall/shop/save',
          method:'post',
          params:{
            count: this.courseNum, // 数量
            couponCode:'', // 优惠劵编码
            openTimeId:this.openTimeId, // 开班设置id
            price: (this.productObj?this.productObj.price : this.shopDetail.couponPrice), // 价格  优惠价格-还没有计算优惠券
            productId: (this.productObj?this.productObj.relatedProductId : this.shopDetail.productId), // 商品id
            type: 1, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
            userNo:this.$store.state.userInfo.userId, // 用户
          }
        }).then(res=>{
          if(res.code == 200 && res.success){
            this.$message.success('添加购物车成功');
            this.mollJoinCar();
            return true;
          }else{
            this.$message.error(res.message)
            return false;
          }
        })
      }
    },

    // 改变商品数量
    changeCourseNum(i) {
      if (i < 0) {
        if (this.courseNum != 1) {
          this.$set(this, "courseNum", this.courseNum + i);
        }
      } else {
        this.$set(this, "courseNum", this.courseNum + i);
      }
    },

    // 批量加入购物车判断
		mollJoinCar(){
			let record = [];
			if(this.productList && this.productList.length){
				this.productList.map(item=>{
					if(item.count > 0){
						record.push({
							count:item.count, // 数量
							couponCode:'', // 优惠劵编码
							openTimeId:'', // 开班设置id
							productId:item.relatedProductId, // 商品id
							type:item.productType, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
							userNo:this.$store.state.userInfo.userId, // 用户
						})
					}
				})
			}
			if(record.length){
				// 相关商品批量加入购物车
				this.joinCarAll(record);
			}else{
        this.$refs['sidebar'].getShopList()
      }
		},
		// 批量加入购物车
		joinCarAll(e){
			this.$ajax({
				url:'/hxclass-pc/pc-mall/app/batch/save',
				method:'post',
				params:e
			}).then(res=>{
				if(res.code == 200 && res.success){
          this.$refs['sidebar'].getShopList()
				}else{
					this.$message.error(res.message)
				}
			})
		},
    // 商品购买前校验
    beforeBuyCheck(isCheck){
      let check = false
      if(!this.$store.state.userInfo.userId){
        check = true
				this.$router.push('/login/loginIndex?type=2');
			} else if(this.specsList.length && !this.specsId){
        check = true
				this.$message.error('请先选择课程规格，再进行购买。');
			} else if(this.shopDetail.setOpenClass && !this.classList?.length){
        check = true
				this.$message.error('暂无可选择班级期数无法进行购买');
			} else if(!!this.shopDetail.userAuthentication && !this.$store.state.userInfo.userType){
				// return this.$message.error('购买该课程需要身份认证');
        check = true
        this.confirmPopup = true;
			} else if(this.classList?.length && !this.openTimeId){
        check = true
        this.tipsText = '请先选择课程期数，再进行购买。';
        this.isPublicTipsShow = true
      } else if(this.shopDetail.prebuyNotice && !this.prebuyNoticeFlag && isCheck) {
        check = true
        this.prebuyNoticeVisible = true
        // this.$message.warning('请阅读购买须知');
      }
      return check
    },
    
    async buyNow(){
      if(this.shopDetail.have && !this.shopDetail.buyLimitNum){
        this.goStudy(this.shopDetail);
        return false;
      }

      if(this.beforeBuyCheck(true)){return}
      
      let res = await this.checkCanBuy()
      if(res.code == 200 && res.success){
        
        let list = [];
        let productList = [];
        this.seriesproductList.map(item=>{
          productList.push({
            productId:item.relatedProductId, // 商品id
            count:this.courseNum, // 数量")
            openTimeId:'', // 班级id
            type:item.productType, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
            province:'', // 省
            city:'',// 市
            noGift:1, // 是否为赠品 0否1是t
          })
        })
        list.push({
          productId:(this.productObj?this.productObj.relatedProductId : this.shopDetail.productId), // 商品id
          count:this.courseNum, // 数量")
          openTimeId:this.openTimeId, // 班级id
          type:1, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
          province:'', // 省
          city:'',// 市
          noGift:0, // 是否为赠品 0否1是t
          productList:productList
        })
        // 相关商品
        this.productList.map(item=>{
          if(item.count > 0){
            list.push({
              productId:item.relatedProductId, // 商品id
              count:item.count, // 数量")
              openTimeId:'', // 班级id
              type:item.productType, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
              province:'', // 省
              city:'',// 市
              noGift:0, // 是否为赠品 0否1是t
            })
          }
        })
        this.$store.commit('updatelist',list);
        this.$store.commit('updateType',1);
        this.$store.commit('updateInvoiceId',null);
        this.$store.commit('updateShortInvoiceId',null);
        this.$store.commit('updateAddress',null);
        this.$store.commit('updateCouponList',null);
        this.$router.push('/order/confirmOrder')
      }
    },
    // 加入学习
    async joinStudy() {
      if(this.shopDetail.have){
        this.goStudy(this.shopDetail);
        return false;
      }
      if(this.beforeBuyCheck(true)){return}

      let res = await this.checkCanBuy()
      if(res.code == 200 && res.success){
        this.$ajax({
          url:'/hxclass-pc/course/join-study',
          method:'post',
          params:{
            courseId:this.shopDetail.courseId, //	课程id
            openTimeId:this.openTimeId, //	开课时间id
            userId:this.$store.state.userInfo.userId, //	用户id
          }
        }).then(res=>{
          if(res.code == 200 && res.success){
            this.$message.success('已成功加入');
            this.shopDetail.have = 1
            this.shopDetail.couponPrice = 0
            this.getTree();
          }else{
            this.$message.error(res.message);
          }
        })
      }
    },
    // 取消提交资质
    cancelQuali(){
      this.qualificationvisible = false;
    },
    // 提交资质
    confirmQuali(e){
      if(!e.imageUrl){
        return this.$message.error('请上传相关资质')
      }else if(!e.type){
        return this.$message.error('请选择资质类型')
      }
      // else if(!e.type){
      //   return this.$message.error('请选择资质类型')
      // }
      this.$ajax({
        url:'/hxclass-pc/course/course-buy-audit/commit',
        method:'post',
        params:{
          certifTempId: e.type, // 资质模板id
          code:e.code, //
          content: e.textarea, // 内容
          courseId: this.courseId, // 课程id
          relevantFile: e.fileSrc, // 相关文件
          relevantFileName: e.fileName, // 相关文件名称
          relevantProof: e.imageUrl, // 相关证明
          userId: this.$store.state.userInfo.userId, // 用户id
        }
      }).then(res=>{
        if(res.code == 200){
          this.visible = true;
          this.fontText = res.message;
          this.qualificationvisible = false;
        }else{
          return this.$message.error(res.message);
        }
      })
    },
    OpenCertificateModal(e){
      this.certificateForm = e
      this.certificateShow = true
    },
    // 清除富文本标签
    regtxt(e){
      let a= e.replace(/<[^>]+>/g,'')
      return a
    },

    selectOpenTimeId(e) {
      if (e.currentNumber < 1) {
        return this.$message.error("该班级已报满，请选择其他班级");
      }
      this.classItem = e;
      this.openTimeId = e.openTimeId;
    },
    randomColor() {
      var color = "#";
      for (var i = 0; i < 6; i++)
        color += parseInt(Math.random() * 16).toString(16);
      return color;
    },
    // 锚点定位
    navClick(e,index) {
      this.pointNum = index;
      const returnEle = document.querySelector(e.navTarget); //productId是将要跳转区域的id
      if (!!returnEle) {
        returnEle.scrollIntoView(true); // true 是默认的
      }
      let offsetTop = document.querySelector(e.navTarget).offsetTop + 260;
      window.scrollTo(0,offsetTop);
    },
    handleScroll() {
      const myDiv = this.$refs.barTabNav;
      const distanceToTop = myDiv.getBoundingClientRect().top;
      this.barTabNavTop = distanceToTop
    },
    // 价格处理
    managePrice(con) {
      let txt = (con * 1).toFixed(2).split(".");
      return txt;
    },
    // 教师列表处理
    manageTeacher() {
      if (!this.shopDetail.teacherList || this.shopDetail.teacherList.length == 0) {
        return;
      }
      let teacherlabel = [[]]; //教师标签
      let teacherSwiper = 0; //教师数组个数
      let count = 0; //计数一组放四个
      // 教师
      this.shopDetail.teacherList.map((item, index) => {
        // 标签
        item.title.split(",").map((ite, index) => {
          teacherlabel[teacherSwiper].push(ite);
          count++;
          // 超过4个,原判定是等于4就++
          // if (count == 4) {
          if (count == 4) {
            teacherlabel.push([]) //添加新分组
            teacherSwiper++ //分组+1
            count = 0 //计数清零
          }
        });
        if (item.title.split(",").length%4==0) {
           teacherSwiper--;
           teacherlabel.pop();
        }
        // 赋值
        this.$set(this.shopDetail.teacherList[index], 'title', teacherlabel)
        teacherlabel = [[]]
        teacherSwiper = 0
        count = 0
      })
    },

    // 查询目录信息
    getTree() {
      let url =  (this.shopDetail.have == 1 && this.shopDetail.watchByCatalogueOrder != 1) ? "/hxclass-pc/course/course-section/random/tree" : "/hxclass-pc/course/course-section/tree"
      this.$ajax({
        url: url,
        params: {
          courseId: (this.productObj ? this.productObj.courseId : this.courseId),
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // console.log(res.data);
          res.data.forEach(element => {
            element.isMenuOpen = true
          });
          this.tree = res.data;
        }
      });
    },

    initVideo({ fileID, psign, disabledRate }) {
      this.player = TCPlayer("player-container-id", {
        // player-container-id 为播放器容器 ID，必须与 html 中一致
        fileID: fileID, // 播放的视频 fileID（必须）
        appID: "1500012233", // 点播账号的appID（必须）
        psign: psign, // 签名
        autoplay: true, // 是否自动播放
        bigPlayButton: false, // 是否显示居中的播放按钮
        controlBar: {
          progressControl: !disabledRate, // 是否显示进度条
          playbackRateMenuButton: !disabledRate, // 是否显示播放速率选择按钮
        },
        plugins: {
          ContinuePlay: {
            btnText: "点击继续观看",
          },
        },
      });
      // 播放开始执行
      this.player.on("play", () => {
        this.paused = false;
        this.ended = false;
      });
      // 播放暂停执行
      this.player.on("pause", () => {
        this.paused = true;
      });
      // 播放结束执行
      this.player.on("ended", () => {
        this.ended = true;
      });
    },

    // 查询视频播放信息
    getVod() {
      if(!this.$store.state.userInfo.userId){
        return this.$router.push('/login/loginIndex?type=2')
      }
      sessionStorage.setItem("selfTess",this.sectionId);
      this.$router.push('/curriculum/course?courseId='+this.$AES.encode_data(this.courseId + "") + '&productId='+this.$route.query.productId + '&isHave=' + this.shopDetail.have)
    },

    // 目录点击
    treeClick(sectionId) {
      if(this.shopDetail.have == 1){
        if (this.shopDetail.validStatus == 2) {
          this.$message.warning("请前往学习中心-在学课程中申请延期");
          return;
        }
        if (this.shopDetail.isOpenClass == 1) {
          let arr = this.shopDetail.startTime.split("-");
          this.$message.warning(
            "课程学习尚未开始，开始时间为" +
              arr[0] +
              "年" +
              arr[1] +
              "月" +
              arr[2] +
              "日"
          );
        } else {
          if(this.shopDetail.watchByCatalogueOrder == 1){
            this.$ajax({
              url: "/hxclass-pc/course/go-study",
              params: {
                courseId: this.shopDetail.courseId,
              },
            }).then((res) => {
              if (res.code == "200" && res.success) {
                this.sectionId = sectionId;
                this.getVod();
              }else{
                if(res.code == -2){
                  let str = res.message + "若已通过其他平台获得资质，请前往app提交资质进行学习。";
                  res.message = str;
                }
                this.$message.warning(res.message);
              }
            });
          } else {
            this.$ajax({
              url: "/hxclass-pc/course/getClickSectionVod",
              params: {
                courseId: this.shopDetail.courseId,
                sectionId: sectionId,
              },
            })
              .then((res) => {
                if (res.code == 200 && res.success) {
                  sessionStorage.setItem('NextVod', JSON.stringify(res.data))
                  this.$router.push(
                    "/curriculum/courseRandom?courseId=" +
                    this.$AES.encode_data(String(this.shopDetail.courseId)) +
                    "&isHave=1"
                  );
                } else {
                  this.$message.warning(res.message);
                }
              })
              .catch((err) => {
                // console.log(err);
              });
          }
        }
      } else {
        this.sectionId = sectionId;
        this.getVod();
      }
    },

    // 获取课程详情
    getCourseDetail() {
      // 记录用户轨迹
      let params = {
        actionDetail: this.$AES.decode_data(this.$route.query.productId + ""), //	访问资源id（如对应的banner、商品和文章id，路径触发先设置首页为1）
        actionType: 3, //	触发分类：1、banner触发(轮播图、弹窗)、2 路径触发(启动访问首页)、3商品详情、4 文章详情
        os: 3, //	1、ios2、安卓 3、PC 4、H5
        userId: this.$store.state.userInfo.userId
      }
      this.saveUserTrack(params);

      this.$ajax({
        url: "/hxclass-pc/course/" + this.$AES.decode_data(this.$route.query.productId + ""),
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (!res.data) {
            return this.$message.error("该课程已下架");
          }
          this.courseId = res.data.courseId;
          this.shopDetail = res.data;

          this.getmaterialList();
          this.getTree();

          // 课程详情介绍子级配置项
					this.detailTabs = [
						{text:'详情介绍', navTarget: '#item1', value: 1},
					]
					
					if(res.data.studentInstructions){
						this.detailTabs.push( {text: '学习指导', navTarget: '#item2', value: 2} )
					}
					if(res.data.relevantCertificateList.length){
						this.detailTabs.push( {text: '相关证书', navTarget: '#item3', value: 3} )
					}
					if(res.data.teacherList.length){
						this.detailTabs.push( {text: '授课老师', navTarget: '#item4', value: 4} )
					}

          // 处理教师列表数据
          this.manageTeacher();
          this.getshopCourseDateList(this.courseId); // 获取课程期数
          this.getCourseFreeSelection(this.courseId); '' // 获取课程免费观看章节
          if (res.data.teacherList && res.data.teacherList.length) {
            res.data.teacherList.map((item) => {
              this.colorList.push(this.randomColor());
            });
          }
          this.imgSrc = res.data.picture;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 查询销售关系商品
    productRelation(){
      this.$ajax({
        url: "/hxclass-pc/pc-mall/productRelation/list/client/" + this.$AES.decode_data(this.$route.query.productId + ""),
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 赠品数据处理
					let arr = res.data.filter(item=>{
						return item.relationType == 1;
					})
					this.$set(this,'seriesproductList',arr);

          // 相关商品
					this.productList = res.data.filter(item=>{
						return item.relationType == 2;
					})
					this.productList.map(item=>{
						item['count'] = 1;
						return item;
					})

          // 规格商品处理
					let specsList = res.data.filter(item=>{
						return item.relationType == 4;
					})
					this.$set(this,'specsList',specsList);
					
          // 系列数据处理
					let collectionList = res.data.filter(item=>{
						return item.relationType == 5
					})
					this.$set(this,'collectionList',collectionList);
        } else {
          this.$message.error(res.message);
        }
      });
    },

    selectTime(){
      //时间戳
      // let time = +new Date();
      //时间戳
      let time = new Date().getTime();
      var date = new Date(time + 8*3600*1000); // 增加8小时
      return date.toJSON().substr(0, 19).replace('T', ' ');
    },

    // 领取优惠券
    receiveCoupon(e) {
      if(!this.$store.state.userInfo.userId){
        return this.$router.push('/login/loginIndex?type=2')
      }
      if(this.receiveCouponflag){
        return 
      }
      this.receiveCouponflag = true;
      this.$ajax({
        url:'/hxclass-pc/pc-mall/app/save-draw',
        method:'post',
        params:{
          couponId:e.couponId
        }
      }).then(res=>{
        this.receiveCouponflag = false;
        if(res.code == 200 && res.success){
          this.$message.success('领取成功');
          this.getCouponList();
        }else{
          this.$message.error(res.message);
        }
      })
    },

    // 获取优惠券列表
    getCouponList(e) {
      this.$ajax({
        url: "/hxclass-pc/pc-mall/product/coupon",
        method: "get",
        params: {
          productId: this.$AES.decode_data(this.$route.query.productId + ""), //	购物车商品 1,2,3,4
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.couponList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 商品详情-查询课程期数
    getshopCourseDateList(e) {
      this.$ajax({
        url: "/hxclass-pc/course/course-open-time/by-id",
        params: {
          courseId: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.classList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 查询课程的免费试看章节
    getCourseFreeSelection(courseId){
      this.$ajax({
        url: "/hxclass-pc/course/course-free-trial-section/" + courseId,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.freeSelectionObj = res.data
        }
      });
    },

    onPlayFreeVideo(){
      if(!this.$store.state.userInfo.userId){
        return this.$router.push('/login/loginIndex?type=2')
      }

      var courseId = (this.productObj ? this.productObj.courseId : this.courseId)
      var productId = (this.productObj ? this.productObj.relatedProductId : this.shopDetail.productId)

      sessionStorage.setItem("selfTess",this.freeSelectionObj.sectionId);
      this.$router.push('/curriculum/course?courseId=' + this.$AES.encode_data(courseId + "") + '&productId=' + this.$AES.encode_data(productId + "") + '&isHave=' + this.shopDetail.have)
    },

    // 获取学习资料列表
    getmaterialList() {
      this.$ajax({
        url: "/hxclass-pc/course/select/byCourseId",
        params: {
          courseId: this.courseId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.materialList = res.data;
        }else {
          this.$message.error(res.message)
        }
      });
    },
    lookmaterial(e) {
      if(!e.url){
        return
      }
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/loginIndex?type=2');
			}
      if(!this.shopDetail.have){
        return this.$message.warn('请先购买该课程！');
      }

      // 点击量
      this.$ajax({
        url: "/hxclass-pc/course/select/updateViewNum?id=" + e.id,
        method: "put",
      }).then((res) => {});
      
      if(e.downloadFlag == 1){
        window.open(e.url);
      } else{
        window.open('/knowledgeMall/PdfView?link=' + this.$AES.encode_data(String(e.url)));
      }
    },

    // 记录用户轨迹
    saveUserTrack(params) {
      userTrack(params)
    },
    // 去认证
    goAuthentication(){
      this.$router.push("/personalCenter/identityAuthentication")
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.clientWidth = document.body.clientWidth;
    // this.getShopDetail();
    if (this.$route.query.productId) {
      this.getCourseDetail();
      // 获取优惠券列表
      this.getCouponList(1);
      // 查询销售关系
      this.productRelation()
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        // console.log(document.body.clientWidth);
        that.clientWidth = window.screenWidth;
      })()
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  // 自定义的侦听器
  watch: {
    "document.body.clientWidth"(newVal,oldVal){
      // console.log(newVal)
    },
    "$route.query.productId"(newVal,oldVal){
      this.getCourseDetail();
      // 获取优惠券列表
      this.getCouponList(1);
      // 查询销售关系
      this.productRelation()
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-modal-close-x{
  width: 89px;
  height: 89px;
  line-height: 89px;
}
/deep/.ant-modal-header{
  border-bottom: 0;
  padding-left: 30px;
  padding-top: 30px;
  .ant-modal-title{
    font-weight: 500;
    font-size: 20px;
    font-family: PingFang SC, PingFang SC;
    color: #333333;
    line-height: 23px;
  }
}
/deep/.ant-modal-body{
  padding: 0 30px;
}
/deep/.ant-modal-footer{
  text-align: center;
  padding-bottom: 25px;
  padding-top: 7px;
  border-top: 0;
  .enterNote{
    height: 40px;
    background: #15B7DD;
    box-shadow: 0px 4 4px 0px rgba(49,188,221,0.25);
    border-radius: 20px 20px 20px 20px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
}
.teacherItem {
  padding-top: 34px;
  // width: 1170px; //没必要写宽度
  margin: 0 auto;
  position: relative;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 27px;
  padding-left: 26px;
  margin-bottom: 20px;
  /deep/.swiper-wrapper {
    padding-bottom: 25px;
  }
  /deep/.swiper-pagination {
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: #e5e5e5;
      opacity: 1;
      margin: 0 8px;
    }
    .swiper-pagination-bullet-active {
      background-color: #15b7dd;
    }
  }
  .fly {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 27px;
    height: 27px;
  }
  .detail {
    margin-left: -26px;
    padding-top: 35px;
    font-size: 18px;
    font-family: PingFangMedium;
    font-weight: 400;
    // color: #333333;
    line-height: 36px;
  }
  .flexBox {
    display: flex;
    .imgTitle {
      position: relative;
      margin-right: 20px;
      height: 212px;
      .pictureName {
        width: 152px;
        height: 171px;
        background: red;
        border-radius: 16px 16px 0 0;
        overflow: hidden;
        img {
          width: 152px;
          height: 191px;
          margin-top: -20px;
          position: absolute;
          z-index: 1;
          border-radius: 16px 16px 0 0;
        }
      }
      .name {
        position: relative;
        z-index: 10;
        margin-top: 5px;
        width: 152px;
        line-height: 24px;
        font-size: 16px;
        font-family: PingFangMedium;
        color: #333333;
        background: red;
        border-radius: 4px;
        text-align: center;
        padding: 6px 10px;
      }
      .border {
        width: 182px;
        height: 202px;
        border: 1px solid red;
        position: absolute;
        top: 20px;
        left: -15px;
      }
      .triangle {
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-left: 24px solid red;
        border-bottom: 12px solid transparent;
        position: absolute;
        bottom: -35px;
        left: -25px;
        opacity: 0.5;
      }
    }
    .titleList {
      padding-left: 34px;
      padding-top: 28px;
      margin-left: 0;
      .titleItem {
        margin-bottom: 19px;
        font-size: 20px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
        span {
          display: inline-block;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: red;
          margin-right: 8px;
        }
      }
      .titleItem:last-child {
        margin: 0;
      }
    }
  }
}
.teacherItem:last-child {
  margin-bottom: 0;
}
.relevantCertificateList {
  width: 604px;
  margin: 0 auto;
  img {
    width: 604px;
  }
  p {
    text-align: center;
    font-size: 18px;
    font-family: PingFangMedium;
    color: #000000;
    line-height: 27px;
    margin-top: 12px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width:16000px) {
  .custom-slick-arrow {
    cursor: pointer;
    margin: auto 0;
    width: 24px;
    height: 24px;
    font-size: 12px;
    color: #999999;
    background: #f5f5f5;
    border-radius: 12px;
    display: flex ;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .custom-slick-arrow:before {
    display: none;
  }
  .custom-slick-arrow:hover{
    color: #ffffff;
    background: #15B7DD;
  }
}
.main-box {
  padding-top: 80px;
  padding-bottom: 60px;
  .content {
    margin-top: 24px;
    padding: 20px 60px 20px 20px;
    border-radius: 5px;
    background: #ffffff;
    display: flex;
    .shopimg {
      .img {
        width: 496px;
        border-radius: 5px;
        background-repeat: no-repeat;
        object-fit: cover;
      }
      @media screen and (max-width:16000px) {
        .img{
          width: 426px;
        }
      }

      .equity {
        padding: 4px 8px;
        background-color: #F6F6FC;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        line-height: 18px;
        border-radius: 2px;
        margin-top: 10px;
        img{
          position: relative;
          top: -1px;
          width: 10px;
        }
        a{
          color: #026626;
        }
      }
    }
    .shopDetail {
      margin-left: 30px;
      flex: 1;
      .flex-between{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .name-inner{
        align-items: baseline;
        .name {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 24px;
          color: #333333;
          line-height: 36px;
        }
        .tags{
          min-width: 100px;
          text-align: right;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 15px;
        }
      }
      .price-inner{
        .price{
          color: #FF452D;
          display: flex;
          align-items: center;
          .new{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            span{
              font-size: 32px;
              line-height: 48px;
            }
          }
          .old{
            font-size: 16px;
            font-family: PingFangMedium;
            text-decoration: line-through;
            color: #999999;
            line-height: 21px;
            margin-left: 9px;
          }
          .coupon-btn{
            position: relative;
            margin-left: 10px;
            img{
              width: 53px;
              cursor: pointer;
            }
            .couponList{
              position: absolute;
              z-index: 10;
              top: 36px;
              left: -150px;
              background: #FFFFFF;
              border-radius: 5px;
              box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.1600);
              padding: 30px 20px 16px;
              .coupon{
                width: 322px;
                margin-bottom: 16px;
                padding-right: 15px;
                height: 80px;
                display: flex;
                align-items: center;
                background-image: url(~@/assets/image/knowledgeMall/coupon_1.png);
                background-size: 100% 100%;
                .couponLeft{
                  padding-right: 20px;
                  width: 93px;
                  text-align: center;
                  .price{
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    font-size: 16px;
                    font-family: PingFangRegular;
                    font-weight: 300;
                    color: #3A3330;
                    line-height: 37px;
                    span{
                      font-size: 30px;
                      line-height: 45px;
                      font-family: PingFangMedium;
                    }
                  }
                  .condition{
                    margin-top: -5px;
                    width: 80px;
                    font-size: 10px;
                    font-family: PingFangRegular;
                    font-weight: 400;
                    color: #3A3330;
                    line-height: 15px;
                    transform: scale(0.9);
                  }
                }
                .couponRight{
                  flex: 1;
                  .couponName{
                    margin-left: -10px;
                    font-size: 14px;
                    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                    font-weight: 500;
                    color: #333333;
                    line-height: 14px;
                  }
                  .useTime{
                    margin-left: -10px;
                    margin-top: 7px;
                    font-size: 12px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                    line-height: 8px;
                    transform: scale(0.9);
                  }
                  .scope{
                    margin-top: 8px;
                    font-size: 10px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #842C17;
                    line-height: 10px;
                  }
                }
                .couponBtn{
                  cursor: pointer;
                  background: linear-gradient(134deg, #FFE4C2 0%, #FFD39F 100%);
                }
                .couponStyle{
                  background: linear-gradient(134deg, #FFE4C2 0%, #FFD39F 100%);
                }
                .couponBtn,
                .couponStyle{
                  text-align: center;
                  width: 60px;
                  height: 25px;
                  border-radius: 2px;
                  font-size: 14px;
                  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                  font-weight: 500;
                  color: #333333;
                  line-height: 25px;
                }
              }
              .coupon:last-child{
                margin-bottom: 0;
              }
              .geted{
                opacity: 0.5;
                // background-image: url(~@/assets/image/knowledgeMall/coupon_2.png);
              }
            }
          }
        }
        .label-list{
          display: flex;
          .label-item{
            display: inline-block;
            font-size: 12px;
            color: #15B7DD;
            padding: 4px 6px;
            margin-right: 10px;
            background: rgba(21,183,221,0.1);
            border-radius: 2px;
            cursor: pointer;
          }
        }
        .describe {
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #1e97b4;
          line-height: 21px;
        }
      }
      .gift-inner{
        border-radius: 2px 2px 2px 2px;
        background-color: #F6F6FC;
        // border: 1px solid #D2D2EA;
        // background-image: url(~@/assets/image/knowledgeMall/20241011-100256.jpg); /* 设置背景图片 */
        // background-repeat: repeat-x; /* 水平平铺背景图片 */
        // background-size: 20px 100%;
        padding: 14px 12px;
        margin-top: 8px;
        .side-item{
          display: flex;
          align-items: flex-start;
          margin-bottom: 10px;
          .title{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 21px;
            margin-right: 10px;
            img{
              position: relative;
              bottom: 2px;
              width: 13px;
            }
          }
          .side-r{
            .name{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #666666;
              line-height: 21px;
              cursor: pointer;
              margin-bottom: 8px;
              .see-btn{
                margin-left: 10px;
                width: 65px;
              }
            }
            .name:last-child{
              margin-bottom: 0;
            }
            .flex{
              display: flex;
              margin-bottom: 10px;
              cursor: pointer;
              .photo{
                width: 40px;
                border-radius: 2px;
                margin-right: 4px;
                background-repeat: no-repeat;
                object-fit: cover;
              }
            }
            .flex:last-child{
              margin-bottom: 0;
            }
          }
        }
        .side-item:last-child{
          margin-bottom: 0;
        }
      }
      .gift-inner:hover{
        // box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.05);
        // border-radius: 2px 2px 2px 2px;
      }
      .schedule-inner{
        margin-top: 14px;
        display: flex;
        align-items: baseline;
        .title{
          display: inline-block;
          min-width: 80px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 21px;
        }
        .list{
          display: flex;
          flex-wrap: wrap;
          .item{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 21px;
            padding: 4px 12px;
            margin-right: 12px;
            margin-bottom: 8px;
            border-radius: 2px 2px 2px 2px;
            border: 1px solid rgba(0,0,0,0.1);
            cursor: pointer;
          }
          .item-hover{
            position: relative;
            color: #00BDE9;
            border: 1px solid #E6F8FD;
            background: #E6F8FD;
            &::after{
              content: '';
              position: absolute;
              right: 0;
              bottom: 0;
              width: 22px;
              height: 17px;
              z-index: 3;
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-image: url("~@/assets/image/knowledgeMall/2024090604.png");
            }
          }
          .class{
            min-width: 195px;
            .name{
              font-weight: 500;
              img{
                width: 16px;
              }
            }
            .num{
              color: #333333;
              i{
                font-style: normal;
                color: #00BDE9;
              }
            }
            .time{
              margin-top: 5px;
            }
          }
          
          // 配套商品
          .together{
            display: flex;
            width: 300px;
            img{
              border-radius: 2px;
              width: 43px;
              height: 43px;
              background-repeat: no-repeat;
              object-fit: cover;
              margin-right: 12px;
            }
            .side-r{
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              font-family: PingFang SC, PingFang SC;
              .name{
                max-width: 219px;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                line-height: 21px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .price{
                font-weight: 600;
                font-size: 12px;
                color: #FF452D;
                line-height: 18px;
              }
            }
          }
        }
        .address{
          font-family: PingFang SC, PingFang SC;
          padding: 4px 12px;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 21px;
          background: #F6F6FC;
          border-radius: 2px 2px 2px 2px;
          margin-top: 4px;
          img{
            position: relative;
            bottom: 2px;
            width: 9px;
            margin-right: 4px;
          }
        }
      }
      .step-num {
        padding: 14px 0;
        font-size: 14px;
        font-family: PingFangMedium;
        color: #333333;
        display: flex;
        align-items: center;
        .count {
          width: 110px;
          height: 32px;
          display: flex;
          align-items: center;
          padding: 0 6px;
          border-radius: 5px;
          border: 1px solid #E6E6E6;
          .icon {
            font-size: 14px;
            color: #666666;
          }
          ::v-deep.ant-input-number-handler-wrap {
            display: none;
          }
          ::v-deep.ant-input-number {
            height: 32px;
            width: 70px;
            border: 1px solid #E6E6E6;
            margin: 0 10px;
            border-radius: 0;
          }
          ::v-deep.ant-input-number-input {
            height: 32px;
            line-height: 32px;
            text-align: center;
          }
          ::v-deep.ant-input-number:focus {
            box-shadow: none !important;
          }
        }
      }
      .bayNote{
        margin-top: 24px;
        .funcBtn{
          cursor: pointer;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #15B7DD;
          text-decoration: underline;
        }
      }
      .buyBtns{
        margin-top: 20px;
        justify-content: right;
        .btn{
          width: 142px;
          height: 46px;
          font-weight: 500;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          overflow: hidden;
          color: #ffffff;
          cursor: pointer;
          margin-left: 16px;
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: -100%;
            background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, .5), transparent);
            transition: all .5s;
          }
          p {
            margin-left: 5px;
          }
          &:hover::before {
            left: 100%;
          }
        }
        // 试看按钮
        .try-button{
          border: 1px dashed #FF7A00;
          color: #FF7A00;
          &:hover::before {
            left: -100%;
          }
        }
        // 购物车按钮
        .car-button {
          background: #15b7dd;
        }
        // 购买按钮
        .buy-button{
          background: #FF7A00;
        }
        
        // 禁用按钮
        .isBuy{
          cursor: initial;
          background: #31BCDD;
          opacity: 0.5;
        }
      }
    }
  }
}
.tabs-box {
  margin-top: 16px;
  background-color: #ffffff;
  position: relative;
  padding: 0 27px;
  border-radius: 5px;
  .tabs {
    height: 62px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 25px;
    .tabsdiv {
      font-size: 20px;
      width: calc(100% / 4);
      font-family: PingFangMedium;
      position: relative;
      color: #666;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tabs-hover{
      color: #00BDE9;
      &::after{
        content: '';
        position: absolute;
        bottom: -21px;
        width: 22px;
        height: 3px;
        background: #00BDE9;
        border-radius: 2px 2px 2px 2px;
      }
    }
  }
}
.contenttab {
  overflow: hidden;
  .barTabNav{
    margin-bottom: 15px;
    text-align: center;
    .tab-item{
      display: inline-block;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      line-height: 34px;
      margin: 0 20px;
      padding: 0 20px;
      cursor: pointer;
    }
    .tab-hover{
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      background: #00BDE9;
      border-radius: 20px;
      height: 34px;
    }
  }
  .barTabNav-default{
    max-height:0px;
    overflow: hidden;
    transition:max-height .2s;
  }
  .barTabNav-Top{
    position: fixed;
    background: #ffffff;
    max-height:70px;
    line-height: 70px;
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.05);
    height: 70px;
    top: 58px;
    z-index: 9;
    width: 1146px;
  }
  .h4{
    text-align: center;
    margin: 17px 0;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      bottom: 2px;
      width: 63px;
      height: 4px;
      background-image: url("~@/assets/image/knowledgeMall/2024091003.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }
    .title{
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #000000;
      line-height: 24px;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        left: -16px;
        top: 6px;
        width: 10px;
        height: 11px;
        background-image: url("~@/assets/image/knowledgeMall/2024091001.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
      }
      &::after{
        content: '';
        position: absolute;
        right: -17px;
        top: 6px;
        width: 10px;
        height: 11px;
        background-image: url("~@/assets/image/knowledgeMall/2024091002.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
      }
    }
  }

  /deep/ img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
  .material{
    transition: all 0.5s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FAFAFA;
    border-radius: 6px;
    padding: 17px 28px 17px 24px;
    margin-bottom: 20px;
    .mess,.lookBtn{
      display: flex;
      align-items: center;
      p{
        margin-right: 6px;
      }
    }
    .mess{
      .materialIcon{
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
      .courseName{
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        margin-right: 15px;
      }
      .courseIcon{
        width: 32px;
        height: 17px;
        margin-top: 2px;
      }
      .sectionName{
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin-top: 2px;
      }
    }
    .lookBtn{
      cursor: pointer;
      font-size: 14px;
      min-width: 50px;
      justify-content: right;
      margin-left: 10px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #C1C1C1;
      line-height: 21px;
      .icon{
        font-size: 10px;
      }
    }
  }
  .material:hover {
    position: relative;
    z-index: 2;
    box-shadow: 0px 4px 10px 1px rgba(93, 131, 167, 0.15);
  }
  .collection-list{
    overflow: hidden;
    .collection-item{
      float: left;
      display: flex;
      border-radius: 5px 5px 5px 5px;
      border: 1px solid rgba(0,0,0,0.2);
      width: calc((100% - 20px) / 2);
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 12px;
      cursor: pointer;
      .cover{
        width: 140px;
        height: 80px;
        background-repeat: no-repeat;
        object-fit: cover;
      }
      .info{
        flex: 1;
        position: relative;
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name{
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #333333;
          line-height: 18px;
        }
        .price{
          font-weight: 400;
          font-size: 18px;
          color: #EC6C01;
          line-height: 15px;
          i{
            font-style: normal;
            font-size: 12px;
          }
        }
        .go{
          position: absolute;
          right: 0;
          top: 40%;
          font-weight: 500;
          font-size: 16px;
          color: #666666;
          line-height: 24px;
          text-align: left;
          img{
            display: inline-block;
            width: 15px;
          }
        }
      }
    }
    .collection-item:nth-child(2n){
      margin-right: 0;
    }
  }
}
.modal-content {
  padding: 24px 0;
  .tips {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    color: @theme;
    img {
      margin-right: 7px;
    }
  }
  .content {
    font-size: 16px;
    font-weight: 400;
    font-family: PingFang SC-Regular, PingFang SC;
    color: #333333;
    line-height: 23px;
    margin:  20px;
  }
  .btn-foot{
    display: flex;
    justify-content: center;
  }
}
</style>
